

















































import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class DataQuality extends Vue {
  $route!: any;

  get isEnvDevelopment(): boolean {
    const hostnameParts = window.location.hostname.split(".");
    if (hostnameParts[0] === "preprod" || hostnameParts[0] === "test" || hostnameParts[0] === "localhost") {
      return true;
    }
    return false;
  }
}
